import React from "react";
import Modal from "react-modal";
import { css } from "emotion";

import { Panel } from "../panel";
import Button from "muicss/lib/react/button";

import "./SearchPanel.scss";

import {Lookup } from "../lookup";
import {Link} from "react-router-dom";

export class SearchPanel extends React.Component<any, any> {
    state: any;

    constructor(props) {
        super(props);
        Modal.setAppElement("#root");
    }

    render() {
        return <Modal
                    isOpen={this.props.isVisible}
                    onRequestClose={this.props.handleCloseSearch}
                    className="Modal"
                    contentLabel="Search">
            <Panel title={"Find a place"} minHeight={"25rem"}>
                <Lookup
                    handleSearchSelection={this.props.handleSearchSelection}
                    onSearchSelection={this.props.handleCloseSearch} />

                <div className={`${css({ fontSize: "0.7rem", marginTop: 5, display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" })}`}>
                    <Button onClick={() => this.props.handleCloseSearch()}>Cancel</Button>
                    <div>
                        <Link to="/contact" onClick={() => this.props.handleCloseSearch()}>
                            Can"t find what you"re looking for?
                        </Link>
                    </div>

                </div>
            </Panel>
        </Modal>;
    }
}