import React from "react";
import {css} from "emotion";
import format from "date-fns/format";
import isFriday from "date-fns/is_friday";
import isToday from "date-fns/is_today";
import scrollToComponent from 'react-scroll-to-component';

const eventTitles = {
    fajr: "Fajr",
    duhr: "Duhr",
    asr: "Asr",
    maghrib: "Maghrib",
    isha: "Isha",
    jumaah: "Juma'ah",
};

const table = css({
    width: "100%",
    textAlign: "center", 
    lineHeight: "1.8em", 
    borderCollapse: "collapse"
});

export class EventTable extends React.Component<any, any> {
    private defaultComponent: any;
    private rows: any = [];

    constructor(props) {
        super(props);
    }
    
    componentDidUpdate() {
        const date = new Date().getDate();
        const currentMonth = new Date().getMonth() + 1;
        const component = currentMonth === Number(this.props.month) ? this.rows[date - 1] : this.defaultComponent;
        
        scrollToComponent(component);
    }

    render() {
        const thead = css({
            fontWeight: 500, 
            textTransform: "uppercase", 
            display: "block", 
            marginBottom: 5, 
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
        });

        const tbody = css({
            display: "block",
            overflow: "auto",
            height: "calc(100vh - 340px)"
        });

        return <div>
            <table className={table}>
                <thead className={thead}>
                    <tr ref={(row) => (this.defaultComponent = row)}>
                        <td style={{textAlign: "left"}}>Date</td>
                        {Object.values(eventTitles).map((title, index) => 
                            <td key={index}>{title}</td>)}
                    </tr>
                </thead>
                
                <tbody className={tbody}>
                    {this.props.events.map((event, index: number) => 
                        <tr key={index} 
                            className={`tbody ${isToday(new Date(event.date)) ? "mui--bg-accent selected" : ""}`}
                            ref={(row) => (this.rows[index] = row)}>
                            <td style={{textAlign: "left"}}>
                                <span className={"mui--text-accent-secondary"} 
                                    style={{fontWeight: 600}}>{format(event.date, "DD")}</span>
                                &nbsp;
                                <span>{format(event.date, "ddd")}</span>
                            </td>

                            {Object.keys(eventTitles).map((eventKey, index) => 
                                <td
                                    key={index}
                                    title={eventTitles[eventKey]}>
                                    {eventKey !== "jumaah" ? event[eventKey] :
                                        isFriday(event.date) ? event[eventKey] : "-"}
                                </td>)}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>;
    }
}

