import React from "react";
import {css} from "emotion";
import Autosuggest from "react-autosuggest";
import {DebounceInput} from 'react-debounce-input';
import Divider from 'muicss/lib/react/divider';

import { EventsService } from "../events/EventsService";

const theme = {
    container: {
      position: "relative"
    },
    input: {
        height: "calc(32px + 1px)",
        boxSizing: "border-box",
        display: "block",
        backgroundColor: "transparent",
        color: "rgba(0, 0, 0, 0.87)",
        border: "none",
        borderBottom: "1px solid rgba(0, 0, 0, 0.26)",
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.26)",
        outline: "none",
        width: "100%",
        padding: 0,
        boxShadow: "none",
        borderRadius: 0,
        fontSize: 16,
        fontFamily: "inherit",
        lineHeight: "inherit",
        backgroundImage: "none"
    },
    inputFocused: {
        marginBottom: -1,
        borderColor: "#2196F3",
        borderWidth: 2
    },
    inputOpen: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    },
    suggestionsContainer: {
      display: "none"
    },
    suggestionsContainerOpen: {
      display: "block",
      position: "absolute",
      width: "100%",
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0px 2px 0 rgba(0, 0, 0, 0.12)",
      backgroundColor: "white",
      zIndex: 2
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },
    suggestion: {
      cursor: "pointer",
      padding: "10px 20px"
    },
    suggestionHighlighted: {
      backgroundColor: "#ddd"
    }
  };

interface LookupProps {
    handleSearchSelection: (suggestion) => void;
    onSearchSelection?: (suggestion) => void;
}
  
export class Lookup extends React.Component<LookupProps, any> {
    state: any;

    private readonly eventsService: EventsService;

    private onChange: (event, context) => void;
    private onSuggestionsFetchRequested: (context) => void;
    private onSuggestionsClearRequested: () => void;

    constructor(props) {
        super(props);

        this.state = {
            value: "",
            suggestions: []
          };
        
        this.eventsService = new EventsService();

        this.onChange = (_event, {newValue}) => this.setState({value: newValue});
        this.onSuggestionsClearRequested = () => this.setState({suggestions: []});
        this.onSuggestionsFetchRequested = async ({value}) => {
            const results = await this.eventsService.searchEventOwner(value);
            this.setState({suggestions: results.length ? results.slice(0, 5) : []});
        };
    }

    render() {
        const inputProps = {
            placeholder: "Enter a name or address",
            value: this.state.value,
            onChange: this.onChange
        };

        const renderSearchInput = (inputProps) =>
        <DebounceInput
            minLength={2}
            debounceTimeout={500}
            autoFocus
            inputRef={inputProps.ref}
            {...inputProps} />;

        return <>
            <Autosuggest
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={this.getSuggestionValue}
                renderInputComponent = {renderSearchInput}
                renderSuggestion={this.renderSuggestion}
                shouldRenderSuggestions={this.shouldRenderSuggestions}
                focusInputOnSuggestionClick={false}
                inputProps={inputProps}
                theme={theme}
            />
        </>;
    }

    private renderSuggestion = (suggestion) => (
        <div>
            <div className={css({fontWeight: 500})}>{suggestion.name}</div>
            <div className={css({fontSize: "0.8rem"})}>{suggestion.fullAddress}</div>
            <Divider />
        </div>
    )

    private getSuggestionValue = (suggestion) => {
        this.props.handleSearchSelection(suggestion);
        
        if (this.props.onSearchSelection) {
            this.props.onSearchSelection(suggestion);
        }

        return suggestion.name;
    }

    private shouldRenderSuggestions = (value) => value.trim().length > 0;

    private onSuggestionSelected = () => this.setState({value: ""});
}