import React from "react";

import "./Loading.scss";

export function Loading() {
    return <div className="loading">
        <div className="dots">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div className="text">loading</div>
    </div>;
}
