import React from "react";
import { SalahFinder } from "../logo/Salahfinder";

export function Contact() {
    return  <div className={"content"}>
        <h3 className={"mui--text-accent"}>
            Contact Us
        </h3>

        <p>To add, or update salah times, or to register a new mosque, 
            please email the following information to <a href="mailto:contact@salahfinder.com">contact@salahfinder.com</a>
        </p>

        <ul>
            <li>Name and Address of the mosque</li>
            <li>Salah timetable</li>
        </ul>

        <p><SalahFinder /> is free to use to host salah times.</p>
    </div>
}
