export class EventsService {
    private readonly host = "n96ja299kc.execute-api.eu-west-2.amazonaws.com";

    constructor() {

    }

    async getEventOwner(key: string) {
        const uri = `https://${this.host}/prod/owners/key/${key}`;
        const response = await fetch(uri);

        return await response.json();
    }

    async searchEventOwner(query: string) {
        const uri = `https://${this.host}/prod/owners/search?query=${query}`;
        const response = await fetch(uri);

        return await response.json();
    }

    async getEvents(key: string, year: number, month: number) {
        const uri = `https://${this.host}/prod/owners/${key}/events/${year}/${month}`;
        const response = await fetch(uri);
        const events: any[] = await response.json();

        return events.sort((a, b) => a.date.localeCompare(b.date));
    }
}