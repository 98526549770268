import React from "react";
import ReactDOM from "react-dom";

import "typeface-roboto";
import "typeface-leckerli-one";

import App from "./app/App";

import "muicss/lib/sass/mui.scss";
import "./index.scss";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
