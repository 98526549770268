import React from "react";

import { css } from "emotion";
import { Lookup } from "../lookup";
import { getPeriod } from "../period";
import { Link } from "react-router-dom";
import { SalahFinder } from "../logo/Salahfinder";

export function Home(props) {
    const width = "calc(50vw)";
    const margin = 15;
    const marginRight = 60;
    const backgroundPosition = "right top";

    const container = css({
        backgroundImage: "url('../sky.jpg')",//"linear-gradient(to top, #bfbfbe, #cecec8)",
        backgroundSize: "contain",
        backgroundRepeat: "repeat-x",
        backgroundPosition
    });

    const imageContainer = css({
        minHeight: "calc(100vh - 65px)", 
        backgroundImage: "url('../minaret-sky.jpg')",//"linear-gradient(to top, #bfbfbe, #cecec8)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition,
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
    });

    const search = css({
        backgroundColor: "white",
        margin,
        marginTop: 0,
        marginRight,
        padding: margin,
        width,
        height: "2em",
        borderRadius: 5,
        boxShadow:"inset 0px 0px 0px 0px white"
    });

    const paragraph = css({
        margin: "0 0 20px",
        // border: "2px solid",
        backgroundColor: "#e3f6ff3d",
        padding: 15
    });

    const handleSearchSelection = (eventOwner) => {
        const period = getPeriod(props.match.params.year, props.match.params.month);
        props.history.push(`/events/${eventOwner.key}/${period.year}/${period.month}`);
    }; 

    return <div>
        <div className={container}>
            <div className={imageContainer}>
                <div className={css({marginRight, marginTop: "calc(10vh)", width})}>
                    <h2 className={`mui--text-headline ${css({color: "white", fontWeight: 500})}`}>
                        Find jamaat times near you.
                    </h2>
                </div>
                
                <div className={search}>
                    <Lookup handleSearchSelection={handleSearchSelection} />
                </div>

                <div className={`mui--text-body1 ${css({marginRight, marginTop: "calc(5vh)", width, color: "white"})}`}>
                    <p className={paragraph}>
                        <SalahFinder /> allows you to view prayer times for places near you</p>
                    <p className={paragraph}>
                        Can't find what you are looking for, or you want to upload a timetable?
                        It's free to use, find out more <Link to="/contact">here</Link></p>
                </div>
            </div>
        </div>

        {/* <div className={css({marginRight, width, marginLeft: 90})}>
            <h2 className={`mui--text-headline`}>
                What is SalahFinder?
            </h2>
        </div> */}

        {/* <Panel title={"Want to upload salah times?"}>
            
        </Panel> */}
    </div>;
}
