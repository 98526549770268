import React from "react";
import {css} from "emotion";

export function SalahFinder() {
    const salah = css({
        fontFamily: "Leckerli One", 
        fontSize: "1.15em",
        color: "#039be5"
    })

    const finder = css({
        fontWeight: 500
    });

    return <span>
        <span className={salah}>Salah</span>
        <span className={finder}>Finder</span>
    </span>;
}