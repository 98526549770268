import React from "react";
import {css} from "emotion";

import MuiPanel from "muicss/lib/react/panel";

export function Panel(props) {
    return <MuiPanel>
        <div className={`mui--bg-accent-dark headline`}>
            <div className="mui--text-title">{props.title}</div>
        </div>

        <div className={css({minHeight: props.minHeight})}>
            {props.children}
        </div>
    </MuiPanel>;
}