import React from "react";
import {css} from "emotion";

import Appbar from 'muicss/lib/react/appbar';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

import { SearchPanel } from "./SearchPanel";
import { getPeriod } from "../period";
import { Link } from "react-router-dom";

export class AppBar extends React.Component<any, any> {
    state : any;

    private handleToggleSearch: () => void;
    private handleSearchSelection: (eventOwner) => void;

    constructor(props) {
        super(props);

        this.state = {
            showSearch: false
        };

        this.handleToggleSearch = () =>
            this.setState(prevState => ({showSearch: !prevState.showSearch}));
        
        this.handleSearchSelection = (eventOwner) => {
            const period = getPeriod(this.props.match.params.year, this.props.match.params.month);
            this.props.history.push(`/events/${eventOwner.key}/${period.year}/${period.month}`);
        };        
    }

    render() {
        const itemContainer = css({display: "flex", alignItems: "center"});
        const itemMargin = css({marginLeft: 15, marginRight: 15});
        const link = css({color: "inherit", ":hover": {textDecoration: "none"}, ":visited": {textDecoration: "none"}});

        return <div className="mui--appbar-height">
            <Appbar className={`${css({position: "fixed", width: "100%", zIndex: 2})}`}>
                <div className={`mui--appbar-height ${itemContainer}`}>
                    <div className={`${itemMargin} ${css({flexGrow: 1})}`}>
                        <h3>
                            <Link to={"/"} className={link}>
                                <span className={css({fontWeight: 500, fontFamily: "Leckerli one", fontSize: "1.05em" })}>Salah</span>
                                <span className={css({fontWeight: 300})}>Finder.com</span>
                            </Link>
                        </h3>
                    </div>
                    
                    <div className={`${itemMargin} ${css({verticalAlign: "center" })}`}>
                        <h3>
                            <span className={"ripple"} onClick={() => this.handleToggleSearch()}>
                                <FontAwesomeIcon icon={faSearch}/>
                            </span>
                        </h3>
                    </div>
                </div>
            </Appbar>  

            <div>
                <SearchPanel isVisible={this.state.showSearch}
                    handleCloseSearch={this.handleToggleSearch}
                    handleSearchSelection={this.handleSearchSelection} />
            </div>
        </div>;
    }
}

