import React from "react";
import {css} from "emotion";
import format from "date-fns/format";

import Divider from 'muicss/lib/react/divider';
import Option from 'muicss/lib/react/option';
import Select from 'muicss/lib/react/select';
import Panel from "muicss/lib/react/panel";

import {getPeriod} from "../period";
import {EventsService} from "./EventsService";
import {EventTable} from "./EventTable";
import {Summary} from "./Summary";
import { Loading } from "../loading/Loading";
import { Link } from "react-router-dom";

interface EventsProps {
    eventsKey: string;
    year: number;
    month: number;
    history: any;
}

export class Events extends React.Component<EventsProps, any> {
    state: any = {
        events: [],
        owner: {},
        loading: true
    };

    private readonly eventsService: EventsService;
    private updatePeriod: (element:any) => void;

    constructor(props: EventsProps) {
        super(props);

        this.eventsService = new EventsService();
        this.updatePeriod = (element) => 
            this.loadEvents(this.props.eventsKey, this.state.year, element.target.value);
    }

    async componentDidMount() {
        await this.loadEvents();
    }

    async componentDidUpdate(prevProps: EventsProps) {
        if (prevProps.eventsKey !== this.props.eventsKey ||
                prevProps.year !== this.props.year ||
                    prevProps.month !== this.props.month) {
            await this.loadEvents();
        }
    }

    render() {
        const table =  <>
            <div className={`events-container`}>
                <EventTable
                    events={this.state.events} 
                    year={this.state.year}
                    month={this.props.month} />
            </div>
            <Divider /> 
        </>;

        const noData =  <div className={css({display: "flex", justifyContent: "center", margin: 30})}>
            <Panel>
                <div className={`mui--bg-primary-dark headline`}>
                    <div className="mui--text-title">No salah times available</div>
                </div>
                <p>
                    {this.state.owner.name} has no salah times available 
                    for {format(this.props.month, "MMMM")} {this.props.year} yet.
                </p>

                <p><Link to="/contact">Would you like to update this?</Link></p>
            </Panel>
        </div>;

        return <div className={"content"}>
            <div className={`events-summary`}>
                <div className={css({textAlign: "right"})}>
                    <div className={css({display: "inline-block", margin: 15})}>
                            <Summary owner={this.state.owner}
                                monthFormatter={this.getFormattedMonth}
                                year={this.props.year}
                                month={this.props.month} />
                    </div>
                </div>

                <Divider />

                <div className={`mui-select ${css({ margin: "5px 0px", fontWeight: 500})}`}>
                    <span>
                        <Select name="input" value={this.props.month} onChange={this.updatePeriod} >
                        {Array.from(Array(13).keys())
                            .slice(1)
                            .map(i => <Option key={i} value={i} 
                                label={`${this.getFormattedMonth(i, "MMMM")} ${this.props.year}`} />)}
                        </Select>
                    </span>
                </div>

                <Divider />
            </div>

            {this.state.events.length > 0 ? table : 
                this.state.loading? <Loading /> : 
                    noData}
        </div>;
    }

    private async loadEvents(eventsKey = this.props.eventsKey, year = this.props.year, month = this.props.month) {
        const period = getPeriod(year, month);
        this.props.history.push(`/events/${this.props.eventsKey}/${period.year}/${period.month}`);

        if (period && period.year && period.month) {
            const owner = await this.eventsService.getEventOwner(eventsKey);
            const events = await this.eventsService.getEvents(owner.eventOwnerId, period.year, period.month);
            this.setState({ 
                owner, 
                events,
                year: period.year,
                loading: false
            });
        }
    }

    private getFormattedMonth(month1Based: number, pattern = "MMM") {
        return format(new Date(new Date().getFullYear(), month1Based - 1), pattern);
    }
}

