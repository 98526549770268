import React, { Component } from "react";
import {BrowserRouter as Router, Link, Route} from "react-router-dom";

import "./App.scss";

import {AppBar} from "./app-bar/AppBar";
import {Events} from "./events/Events";
import {Home} from "./home/Home";
import {Contact} from "./contact/Contact";

class App extends Component<any, any> {
    constructor(props) {
        super(props);
    }

    public render() {
        return <Router>
            {/*  <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/topics">Topics</Link>
                </li>
            </ul>

            <hr /> */}

            <Route exact={true} path="/" component={(props) => <>
                <AppBar {...props} />
                <Home {...props}/>
            </>} />

            <Route exact={true} path="/contact" component={(props) => <>
                <AppBar {...props} />
                <Contact />
            </>} />

            <Route  exact={true} path="/events/:key/:year?/:month?" component={(props) => <>
                <AppBar {...props} />
                <Events {...props}
                    eventsKey={props.match.params.key} 
                    year={props.match.params.year}
                    month={props.match.params.month}
                    theme={props.theme} />
            </>} />
        </Router>;
    }
}

/* function Topics({ match }) {
    return (
        <div>
            <h2>Topics</h2>
            <ul>
                <li>
                    <Link to={`${match.url}/rendering`}>Rendering with React</Link>
                </li>
                <li>
                    <Link to={`${match.url}/components`}>Components</Link>
                </li>
                <li>
                    <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
                </li>
            </ul>

            <Route path={`${match.path}/:topicId`} component={Topic} />
            <Route
                exact={true}
                path={match.path}
                render={() => <h3>Please select a topic.</h3>}
            />
        </div>
    );
} */

/* function Topic({ match }) {
    return (
        <div>
            <h3>{match.params.topicId}</h3>
        </div>
    );
} */

export default App;
