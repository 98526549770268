import React from "react";
import { css } from "emotion";

export function Summary(props) {
    return <>
        <h3 className={"mui--text-accent"}>
            {props.owner.name}
        </h3>

        <p className={`mui--text-accent ${css({marginTop: -10})}`}>
            {props.owner.fullAddress}
        </p>
    </>;
}

